<script setup lang="ts">
  const grayZone = ref<number>(0)
  const stickyBannerHeight = ref<number>(0)
  const numberOfStickyBanners = ref<number>(0)

  const bannerSlots = useBannerSlots()
  const { UseOutsiderBanner } = bannerSlots

  const { contentTypeConfig, getPageTypeConfig, getCategoryConfig } =
    useContentType()

  const { hasStickyBanner: articleStickyBanner } = contentTypeConfig?.value
  const { hasStickyBanner: frontPageStickyBanner } = getPageTypeConfig()
  const { hasStickyBanner: categoryStickyBanner } = getCategoryConfig()

  onNuxtReady(() => {
    setTimeout(() => {
      const StickyBannerContent = document.querySelector(
        '.sticky-banner-content'
      )
      const contentHeight = StickyBannerContent?.offsetHeight

      grayZone.value = 1400
      stickyBannerHeight.value = 2500

      numberOfStickyBanners.value = Math.max(
        0,
        Math.floor((contentHeight - grayZone.value) / stickyBannerHeight.value)
      )
    }, 300)
  })

  const marginTopStyle = computed(() => {
    return {
      marginTop: `${grayZone.value}px`,
    }
  })
</script>

<template>
  <div class="sticky-banner-content bp-main-grid full-content relative">
    <div
      v-if="
        articleStickyBanner || frontPageStickyBanner || categoryStickyBanner
      "
      class="col-span-2 col-start-1 row-start-1 hidden xl:grid"
      :style="numberOfStickyBanners > 0 ? marginTopStyle : null"
    >
      <div
        v-if="numberOfStickyBanners > 0"
        class="relative"
        v-for="stickyBanner in numberOfStickyBanners"
      >
        <div class="sticky top-[150px] z-[2]">
          <UseOutsiderBanner class="!justify-end" />
        </div>
      </div>
    </div>

    <div class="full-content bp-main-grid lg:full-content row-start-1">
      <slot />
    </div>
    <div
      v-if="
        articleStickyBanner || frontPageStickyBanner || categoryStickyBanner
      "
      class="col-span-2 col-start-13 row-start-1 hidden xl:grid"
      :style="numberOfStickyBanners > 0 ? marginTopStyle : null"
    >
      <div
        v-if="numberOfStickyBanners > 0"
        class="relative"
        v-for="stickyBanner in numberOfStickyBanners"
      >
        <div class="sticky top-[150px] z-[2]">
          <UseOutsiderBanner class="!justify-start" />
        </div>
      </div>
    </div>
  </div>
</template>
